import React from "react"
import { IndexTemplateContainer } from "./style"
import { Grid, Row, Col } from "UI/Grid"
import { WhiteTeaser } from "components/Project/WhiteTeaser"
import { LatestResources } from "components/Project/LatestResources"
import { Box, MainColumn, Content } from "UI"
import parse from "html-react-parser"

const CaseStudyIndexTemplate = ({ pages, content, pageIndex }) => {
  return (
    <IndexTemplateContainer>
      {content && content.length ? (
        <MainColumn>
          <Box pb={["5px", "20px", "36px"]}>
            <Content fullWidth>{parse(content)}</Content>
          </Box>
        </MainColumn>
      ) : (
        <Box mt={[-25, -40, -75]} />
      )}
      <Box
        background="#F5F5F5"
        with="100%"
        p={["20px 0 30px 0", null, "60px 0"]}
      >
        <MainColumn>
          <Grid>
            <Row>
              {pages?.map((item, index) => {
                return (
                  <Col key={index} xs={12} sm={6} md={4}>
                    <WhiteTeaser
                      title={item.title}
                      excerpt={item.excerpt}
                      to={item.uri}
                      image={pageIndex && item?.featuredImage?.node}
                    />
                  </Col>
                )
              })}
            </Row>
          </Grid>
        </MainColumn>
      </Box>

      <LatestResources />
    </IndexTemplateContainer>
  )
}

export default CaseStudyIndexTemplate
